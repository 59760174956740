.pulse-badge {
    @include lib-icon-font(
        $_icon-font-content: $icon-expand,
        $_icon-font-color: #927955,
        $_icon-font-size: 13px
    );
    display: block;
    width: $pulse-badge-size;
    height: $pulse-badge-size;
    background-color: #f3f5f6;
    border-radius: 50%;
    transition: transform 0.2s;
    
    span {
        @include lib-text-hide();
    }
    
    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform:
            translateY(-50%)
            translateX(-50%);
        display: block;
        line-height: 1;
    }
    
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: inherit;
        animation: pulse-badge 2s infinite;
    }
    
    &:hover {
        text-decoration: none;
        background-color: darken (#f3f5f6, 5%);
    }
    
    &--active {
        position: relative;
        z-index: 5;
        transform: rotate(135deg);
    }
    
    &--visited {
        &,
        &:hover {
            background-color: darken (#f3f5f6, 5%);
        }
        
        &::after {
            content: none;
        }
    }
}

@keyframes pulse-badge {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
