//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .filter-current {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .filter-current {
        .item {
            font-size: $font-size__s;
        }
    }

    .filter-current-subtitle {
        @include lib-heading(h2);
        display: block;
        font-weight: $font-weight__regular;
        padding-bottom: $indent__s;
        margin-bottom: $indent__s;
        border-bottom: 1px solid $border-color__base;
    }
}
