$header__button-size                       : 45px !default;

@mixin header-button-size() {
    height: $header__button-size;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
        background-color: $header__button__background-color;

        .abs-number-badge {
            background: darken($brand__primary__color, 5%);
        }
    }
}

@mixin header-button-open() {
    position: relative;
    z-index: 105;
    background-color: $color-white;
    color: $brand__primary__color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.page-header {
    position: sticky;
    top: -32px; // 0 minus height of top bar
    background-color: $theme-color-light-gray5;
    margin-bottom: 20px;
}

.sections.nav-sections {
    background-color: $brand__primary__color;
    border-bottom: none;
    z-index: $z-index-2;

    a.navigation-menu__top-link {
        font-family: $font-family-name__alternative;
        font-size: 16px;
        color: white;
        font-weight: bold;
    }
}

.header-top-bar {
    padding: 5px 0;
    background-color: $color-white;
    border-bottom: 1px solid $border-color__base;
    font-size: $font-size__s;

    p {
        margin: 0;
    }

    ul {
        display: flex;

        li {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }
    }

    a {
        color: $primary__color;
        text-decoration: underline;
        text-underline-offset: 0.2rem; // sass-lint:disable-line no-misspelled-properties
        transition: color 100ms;

        &:hover {
            color: $brand__primary__color;
        }
    }
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    @extend .abs-usps;
    display: flex;
}

.header-top-bar-links-container {
    ul {
        list-style-type: none;
        margin: 0;
        padding-left: 0;
    }
}

.header {
    &.content {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &-icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
    }

    .wishlist-wrapper {
        @include lib-list-reset-styles();
        position: relative;

        .label {
            @include lib-visually-hidden();
        }

        .counter.qty {
            @extend .abs-number-badge;
        }

        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-wishlist-empty,
                $_icon-font-color: #111111,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 1
            );
            padding: 0 8px;

            &:hover {
                &::before {
                    color: #111111;
                }
            }
        }
    }

    &.links {
        .counter.qty {
            display: none;
        }
    }

    .minicart-wrapper {
        .action.showcart {
            @include header-button-size();

            &.active {
                @include header-button-open();

                &::before,
                &::after {
                    color: $brand__primary__color;
                }
            }
        }
    }
}

.header-icons {
    margin-left: 0;
}

.login-wrapper .action.showlogin::after,
.login-wrapper .action.showlogin:hover::after,
.minicart-wrapper .action.showcart::before,
.minicart-wrapper .action.showcart:hover::before,
.minicart-wrapper .action.showcart:hover::after {
    color: #111111;
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            a {
                color: $text__color;
            }

            .topbar__list,
            > .header.links {
                @include lib-list-inline();
                font-size: 0;

                &:first-child > li {
                    margin: 0 15px 0 0;
                }

                &:last-child > li {
                    margin: 0 0 0 15px;
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content > &.links {
            @include lib-list-reset-styles();
            display: flex;
            justify-content: flex-end;
            margin-left: 10px;

            .page-header & {
                flex: none;
            }

            > li > a,
            .customer-name > span {
                color: #111111;
                text-transform: capitalize;
                font-size: 13px;
                font-weight: $font-weight__bold;
            }

            > li {
                margin: 0;
            }

            .customer-name {
                @include header-button-size();

                &[aria-expanded=true] {
                    @include header-button-open();

                    > span {
                        color: $brand__primary__color;
                    }

                    button {
                        &::after,
                        &:hover::after,
                        &:focus::after {
                            color: $brand__primary__color;
                        }
                    }

                    svg {
                        fill: $brand__primary__color;
                    }

                    &::after {
                        color: $brand__primary__color;
                    }
                }

                svg {
                    margin-right: $indent__xs;
                }
            }
        }

        .wishlist-wrapper {
            a {
                @include header-button-size();
            }
        }

        .login-wrapper {
            .action.showlogin {
                @include header-button-size();

                &.active {
                    @include header-button-open();

                    svg {
                        fill: $brand__primary__color;
                    }

                    &::after {
                        color: $brand__primary__color;
                    }
                }

                svg {
                    margin-right: $indent__xs;
                }
            }
        }

        &-icons {
            margin-right: -10px;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;
        z-index: $z-index-3;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .sections.nav-sections {
        position: sticky;
        top: 89px; // height of main header bar
    }

    .logo {
        margin: 0;
    }

    .block-search {
        margin: 0 auto;
    }
}

//
//  Large Desktops
//  _____________________________________________

@include min-screen($screen__l) {
    .header {
        &.content > &.links {
            flex: 1;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header {
        padding: 0;
        z-index: 98;
    }

    .header {
        .wishlist-wrapper {
            margin-top: -2px;

            .counter.qty {
                transform: scale(0.6) translateX(10%) translateY(-38%);
                margin-top: 2px;
            }
        }

        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            padding: $indent__s;

            &:before {
                content: '';
                width: 81px; // Width of header-links + header-icons - toggle-nav
                display: block;
                order: 2;
                margin-right: auto;
            }

            .links {
                padding-left: 0;
                margin-bottom: 0;
                margin-left: auto;
                order: 4;

                .showlogin {
                    padding: 0 8px;
                    height: 45px;
                    display: flex;
                    align-items: center;

                    > span {
                        display: none;
                    }

                    &:after {
                        content: none;
                    }

                    &.active {
                        svg {
                            fill: $brand__primary__color;
                        }
                    }
                }

                .login-wrapper {
                    margin-bottom: 0;
                }
            }

            &::after {
                display: none;
            }

            .nav-toggle {
                position: initial;
            }

            .logo {
                margin: 0;
            }

            .block-search {
                width: 100%;
                margin-top: 10px;
            }

            .block-search .label:before {
                margin-right: 0;
            }

            .block-search .control {
                border-top: 0;
            }

            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }

                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }
        }

        .minicart-wrapper .action.showcart {
            padding: 0 8px;

            &:after {
                content: none;
            }

            &:hover,
            &.active {
                background: none;
            }
        }

        .nav-toggle {
            order: 1;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
        }

        .logo {
            order: 3;

            img {
                width: 115px;
                padding: 5px 0;
            }
        }

        &-icons {
            order: 5;
        }

        .block-search {
            order: 6;
        }
    }

    .sections.nav-sections {
        background-color: white;
        border-bottom: $header__navigation__border;

        a.navigation-menu__top-link {
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
            color: #6b6c6f;
            font-weight: 400;
        }

        .showlogin {
            svg {
                display: none;
            }

            &:after {
                content: none;
            }
        }
    }
}
