//
//  Dropdowns variables
//  _____________________________________________

//
//  Variables simple dropdown
//  ---------------------------------------------

$dropdown-actions__padding                        : false !default;

$dropdown-list__min-width                         : 100% !default;
$dropdown-list__width                             : false !default;
$dropdown-list__height                            : false !default;
$dropdown-list__margin-top                        : false !default;
$dropdown-list__position-top                      : 100% !default;
$dropdown-list__position-right                    : false !default;
$dropdown-list__position-bottom                   : false !default;
$dropdown-list__position-left                     : false !default;
$dropdown-list__pointer                           : false !default; // [true|false]
$dropdown-list__background                        : $color-white !default;
$dropdown-list__border                            : false !default;
$dropdown-list__shadow                            : 0 25px 50px -12px rgba(0, 0, 0, 0.25) !default; // [''|none|value]
$dropdown-list__z-index                           : 100 !default;

$dropdown-list-pointer__border                    : $color-gray-light2 !default;
$dropdown-list-pointer__border-radius             : 4px 0 4px 4px !default;
$dropdown-list-pointer__position                  : left !default; // [left|right]
$dropdown-list-pointer__position-top              : -12px !default;
$dropdown-list-pointer__position-left-right       : 10px !default;

$dropdown-list-item__border                       : 0 !default;
$dropdown-list-item__padding                      : 3px $indent__xs !default;
$dropdown-list-item__margin                       : 0 !default;
$dropdown-list-item__hover                        : $menu__item__hover !default;

$dropdown-toggle-icon__content                    : $icon-pointer-down !default;
$dropdown-toggle-icon__font                       : $button-icon__font !default;
$dropdown-toggle-icon__font-size                  : $button-icon__font-size !default;
$dropdown-toggle-icon__font-line-height           : $button-icon__line-height !default;
$dropdown-toggle-icon__font-color                 : $button-icon__color !default;
$dropdown-toggle-icon__font-color-hover           : $button-icon__hover__font-color !default;
$dropdown-toggle-icon__font-color-active          : $button-icon__active__font-color !default;
$dropdown-toggle-icon__font-margin                : $button-icon__margin !default;
$dropdown-toggle-icon__position                   : after !default;
$dropdown-toggle-icon__font-vertical-align        : $button-icon__vertical-align !default;
$dropdown-toggle-icon__text-hide                  : $button-icon__text-hide !default;

$dropdown-toggle-icon__active__content            : $icon-pointer-up !default;

//
//  Variables split dropdown
//  ---------------------------------------------

$dropdown-split-actions__padding                  : 0 $indent__xs !default;
$dropdown-split-toggle__actions__padding          : 4px $indent__xs !default;
$dropdown-split-button__actions__padding          : false !default;
$dropdown-split-toggle__position                  : right !default;

$dropdown-split-list__min-width                   : $dropdown-list__min-width !default;
$dropdown-split-list__width                       : $dropdown-list__width !default;
$dropdown-split-list__height                      : $dropdown-list__height !default;
$dropdown-split-list__margin-top                  : $dropdown-list__margin-top !default;
$dropdown-split-list__position-top                : $dropdown-list__position-top !default;
$dropdown-split-list__position-right              : $dropdown-list__position-right !default;
$dropdown-split-list__position-bottom             : $dropdown-list__position-bottom !default;
$dropdown-split-list__position-left               : $dropdown-list__position-left !default;
$dropdown-split-list__background                  : $dropdown-list__background !default;
$dropdown-split-list__border                      : $dropdown-list__border !default;
$dropdown-split-list__pointer                     : $dropdown-list__pointer !default;
$dropdown-split-list__pointer-border              : $dropdown-list-pointer__border !default;
$dropdown-split-list__pointer-position            : $dropdown-split-toggle__position !default;
$dropdown-split-list__pointer-position-top        : $dropdown-list-pointer__position-top !default;
$dropdown-split-list__pointer-position-left-right : $dropdown-list-pointer__position-left-right !default;
$dropdown-split-list__item-border                 : $dropdown-list-item__border !default;
$dropdown-split-list__item-padding                : $dropdown-list-item__padding !default;
$dropdown-split-list__item-margin                 : $dropdown-list-item__margin !default;
$dropdown-split-list__item-hover                  : $dropdown-list-item__hover !default;

$dropdown-split-button__border-radius-fix         : false !default;
$dropdown-split-list__shadow                      : $dropdown-list__shadow !default; // [''|none|value]
$dropdown-split-list__z-index                     : $dropdown-list__z-index !default;

$dropdown-split-toggle-icon__content              : $dropdown-toggle-icon__content !default;
$dropdown-split-toggle-icon__font                 : $button-icon__font !default;
$dropdown-split-toggle-icon__font-size            : $button-icon__font-size !default;
$dropdown-split-toggle-icon__font-line-height     : $button-icon__line-height !default;
$dropdown-split-toggle-icon__font-color           : $button-icon__color !default;
$dropdown-split-toggle-icon__font-color-hover     : $button-icon__hover__font-color !default;
$dropdown-split-toggle-icon__font-color-active    : $button-icon__active__font-color !default;
$dropdown-split-toggle-icon__font-margin          : $button-icon__margin !default;
$dropdown-split-toggle-icon__font-vertical-align  : $button-icon__vertical-align !default;
$dropdown-split-toggle-icon__position             : $dropdown-toggle-icon__position !default;
$dropdown-split-toggle-icon__text-hide            : true !default; // [true|false]

$dropdown-split-toggle-icon__active__content      : $dropdown-toggle-icon__active__content !default;
