.sticky-header {
    z-index: $z-index__sticky-header;
    position: fixed;
    opacity: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    transform: translateY(100%);
    transition: all 0.2s ease-in-out;

    &--active {
        opacity: 1;
        transition: all 0.2s ease-in-out;
        transform: translateY(0%);
    }

    &__inner {
        max-width: $layout__max-width;
        margin: 0 auto;
        padding: $indent__xs $indent__base;
        display: flex;
        gap: 40px;

        .product-image-container {
            max-width: 30%;
            margin-right: $indent__base;
        }
    }

    &__left {
        display: flex;
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__product-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__product-title {
        font-weight: $font-weight__semibold;
    }

    &__btn {
        &--configure {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size        : 16px,
                $_icon-font-line-height : 1.1,
                $_icon-font-margin      : 0 5px 0 0,
                $_icon-font-position    : before
            );
        }

        &--addtocart {
            @include lib-icon-font(
                $icon-cart,
                $_icon-font-size        : 16px,
                $_icon-font-line-height : 1.1,
                $_icon-font-margin      : 0 5px 0 0,
                $_icon-font-position    : before
            );
        }
    }
}

@include min-screen($screen__m) {
    .sticky-header {
        &__inner {
            max-width: calc(#{$layout__max-width} - #{$indent__xl});
        }
    }
}

@include max-screen($screen__m) {
    .sticky-header {
        &__inner {
            padding: $indent__s 15px;
        }

        &__left {
            display: none;

            .product-image-container {
                margin-right: 5px;
            }
        }

        &__right {
            width: 100%;

            form {
                width: 100%;
            }

            .action {
                width: 100%;
                padding: 12px 16px;
            }
        }
    }
}
