@import 'module/mixins';

@import 'module/collapse';
@import 'module/filter-options';
@import 'module/slider';

.block.filter {
    .am-show-more {
        &.-disabled {
            display: none;
        }

        &:hover {
            color: $theme-text-color-darker;
        }

        &:before,
        &:after {
            background-color: $text__color;
        }
    }

    .filter-options-content {
        .items {
            > .item {
                input[type="checkbox"],
                input[type="radio"] {
                    float: left;
                    margin-right: $indent__s;
                    margin-top: $indent__xs;
                }

                > a {
                    display: inline-block;
                    max-width: calc(100% - 23px);
                }
            }
        }

        form {
            .am-filter-items-category_ids {
                @media all and (max-width: 1023px) {
                    margin-left: 18px;
                }

                .item {
                    a {
                        font-weight: $font-weight__bold;
                        color: $theme-text-color-darker;
                    }

                    .items {
                        .item {
                            a {
                                font-weight: $font-weight__regular;
                                color: $theme-color-dark-gray1;
                            }
                        }
                    }
                }
            }
        }

        .range {
            justify-content: space-between;
        }

        .am-filter-price {
            width: 60px;
            max-width: none;
            padding: $indent__xs;
        }

        .amshopby-currency {
            display: none;
        }
    }

    .range.am-fromto-widget {
        .am-filter-go {
            display: none;
        }
    }

    .amshopby-slider-container {
        .am-slider {
            .ui-slider-handle {
                background-color: $brand__primary__color;
                margin-top: -4px;
                width: 16px;
                height: 16px;
            }
        }

        .ui-slider-range {
            background-color: $secondary__color;
            height: 8px;
        }
    }

    .amshopby-filter-current {
        .amshopby-items {
            padding-left: 0;
        }

        .amshopby-item.item {
            display: flex;
            align-items: center;
            position: relative;

            .amshopby-remove {
                right: 0;
                left: auto;

                &:hover {
                    &:before,
                    &:after {
                        background-color: $brand__primary__color;
                    }
                }
            }

            .amshopby-filter-name {
                order: 1;
                margin-right: 5px;

                &:after {
                    content: ':';
                }
            }

            .amshopby-filter-value {
                order: 2;
                font-weight: $font-weight__bold;
                color: $theme-text-color-darker;
            }
        }
    }

    .filter-actions {
        .filter-clear {
            font-size: 13px;
            color: $text__color;
            text-decoration: underline;

            &:hover {
                color: $brand__primary__color;
            }
        }
    }

    .ui-slider-horizontal {
        height: 8px;
    }
}
