// With this mixin it's possible to create a grid with equally wide blocks.
// Use it on the container element.
// You can use it with Flexbox or set the child elements on 'inline-block'. If the blocks aren't aligned well, you can
// use the @inlineBlockFix parameter and set this on true.
@mixin grid-alt(
    $blocksPerRow,
    $gutterHorizontal: $indent__base,
    $gutterVertical: $indent__base,
    $inlineBlockFix: false
) {
    @if $blocksPerRow > 1 {
        @if $inlineBlockFix == true {
            font-size: 0;
        }

        > * {
            width: calc((100% / #{$blocksPerRow}) - ((#{$gutterHorizontal} * (#{$blocksPerRow} - 1)) / #{$blocksPerRow}));
            margin-bottom: $gutterVertical;

            @if $inlineBlockFix == true {
                font-size: initial;
            }

            &:not(:nth-child(#{$blocksPerRow}n + 1)) {
                margin-left: $gutterHorizontal / 2;
            }

            &:not(:nth-child(#{$blocksPerRow}n)) {
                margin-right: $gutterHorizontal / 2;
            }
        }
    }

    // When @blocksPerRow is set to 1, the blocks will always be full-width
    @if $blocksPerRow == 1 {
        > * {
            width: 100%;
            margin-bottom: $gutterVertical;
        }
    }
}
