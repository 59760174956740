.checkout-billing-address {
    position: relative;
    padding-top: (2 * $indent__base) + $indent__l;

    &:before {
        content: '';
        position: absolute;
        width: calc(100% + (2 * #{$indent__base}));
        height: $indent__l;
        background-color: $color-white;
        left: - $indent__base;
        top: $indent__base;
    }

    .step-title {
        display: block;
    }

    .billing-address-details {
        font-size: 14px;
    }
}
