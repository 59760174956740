.amshopby-slider-container {
    margin: 10px 15px 15px 5px;

    .am-slider {
        .ui-slider-handle {
            margin-left: -5px;
            border-radius: 50%;
            padding: 0;
        }
    }

    .ui-slider-range {
        position: absolute;
    }
}

.filter-options-content {
    .range {
        display: flex;
        align-items: center;
        margin-top: $indent__s;
        text-align: center;
    }
}
