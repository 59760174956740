//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .link-hover {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $brand__primary__color;
            left: 0;
            bottom: 0;
            transform: scaleY(0);
            transition: transform 200ms;
        }

        &:hover {
            text-decoration: none;

            &:after {
                transform: scaleY(1);
            }
        }
    }
}
