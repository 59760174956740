.amcheckout-summary-container {
    .minicart-items {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        .product-image-wrapper {
            height: auto;
            overflow: visible;
        }

        .product-item-details {
            width: calc(100% - 60px);
        }

        .product-item {
            &:not(:last-child) {
                margin-bottom: $indent__s;
                padding-bottom: $indent__s;
                border-bottom: 1px solid $amcheckout-block__border-color;
            }
        }

        .product {
            display: flex;
            justify-content: space-between;
            position: relative;
        }

        .product-item-name {
            margin-bottom: $indent__s;
        }

        .amcheckout-qty {
            display: flex;
            align-items: flex-end;
            margin-top: $indent__s;

            .qty {
                width: 60px;
                height: 32px;
                border-radius: 0;
                border: solid $theme-color-light-gray2;
                border-width: 1px 0;
                text-align: center;
            }

            .label {
                display: block;
                font-size: 12px;
                text-align: center;
            }

            .qty-select {
                &__button {
                    width: 32px;
                    height: 32px;
                    border: 1px solid $theme-color-light-gray2;
                    background-color: $color-white;
                    border-radius: 0;

                    &:before {
                        color: $text__color;
                        transition: 200ms;
                    }

                    &:hover {
                        &:before {
                            color: $brand__primary__color;
                        }
                    }
                }
            }
        }

        .delete {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: url('../images/icons/icon-trash.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px;
            right: 0;
            bottom: 0;
            cursor: pointer;
            border-radius: 50%;
            transition: 200ms;

            &:hover {
                background-color: $color-white;
            }
        }
    }

    .table-totals {
        .mark {
            padding: $indent__xs $indent__xs $indent__xs 0;
        }

        .amount {
            padding: $indent__xs 0 $indent__xs $indent__xs;
        }

        .grand.totals {
            .amount,
            .mark {
                font-weight: $font-weight__bold;
                color: $theme-text-color-darker;

                strong {
                    font-weight: $font-weight__bold;
                }
            }
        }
    }
}

.amcheckout-additional-options {
    .checkout-agreements {
        margin-bottom: $indent__l;
    }

    .checkout-agreement {
        margin-bottom: $indent__base;

        input[type="checkbox"] {
            display: none;

            &:checked {
                + .label {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            + .label {
                cursor: pointer;

                &:before {
                    border-radius: 0;
                    transition: 200ms;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $color-white;

                    &:hover {
                        border-color: $theme-color-light-gray3;
                    }
                }

                &:after {
                    margin-left: 0;
                    border-color: $brand__primary__color;
                    background-color: $brand__primary__color;
                    background-image: url('../images/icons/icon-check.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 10px;
                    border-radius: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                    visibility: hidden;
                    transition: 200ms;
                }
            }
        }

        .action {
            text-align: left;
            padding: 0;
            border: none;
            color: $text__color;
            background-color: transparent;
            text-decoration: underline;

            &:hover {
                color: $text__color;
                background-color: transparent;
            }
        }

        .action-show {
            &--without-link {
                text-decoration: none;
                cursor: default;
            }
        }
    }
}

.amcheckout-block.-summary {
    display: flex;
    flex-direction: column;

    .amcheckout-summary-container {
        order: 1;
    }

    .amcheckout-additional-options {
        order: 3;
        margin-bottom: $indent__base;
    }

    .checkout-payment-method {
        order: 2;

        &.submit {
            order: 4;
        }

        .action.checkout {
            float: none;
            width: 100%;
        }
    }

    .amcheckout-comment {
        label {
            font-size: 14px;
        }
    }
}

.discount-code {
    .action-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.form-discount {
    .label {
        font-size: 14px;
    }

    .action-apply {
        font-family: $font-family-name__alternative;
        padding-right: 0;
        padding-left: 0;
        font-weight: $font-weight__bold;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        color: $color-black;
    }
}
