.am-collapse-icon {
    position: absolute;
    box-sizing: border-box;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;

    &:after {
        position: absolute;
        top: 50%;
        right: 1.2rem;
        box-sizing: border-box;
        width: 0;
        height: 0;
        border-width: 0.5rem 0.4rem 0 0.4rem;
        border-style: solid;
        border-color: #000 transparent transparent;
        content: '';
        transition: all .2s linear;
        transform: translateY(-50%) rotate(-90deg);
    }

    &.-active {
        &:after {
            transform: translateY(-50%) rotate(0deg);
        }
    }

    .am-labels-folding & {
        left: -20px;
    }
}
