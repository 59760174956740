.order-links {
    .item {
        line-height: $tab-control__height;
        margin: 0 0 -1px;

        strong {
            @include lib-css(background, $tab-control__active__background-color);
            border: $border-width__base solid $border-color__base;
            display: block;
            font-weight: $tab-control__font-weight;
            padding: 0 20px;
        }

        a {
            @include lib-css(background, $tab-control__background-color);
            @include lib-css(color, $tab-control__color);
            border: $border-width__base solid $border-color__base;
            display: block;
            font-weight: $tab-control__font-weight;
            padding: 0 20px;

            &:hover {
                @include lib-css(background, $tab-control__hover__background-color);
                @include lib-css(text-decoration, $tab-control__hover__text-decoration);
            }
        }
    }
}

.order-actions-toolbar {
    .action {
        margin: 0 20px 0 0;
    }
}

.order-details-items {
    border: $border-width__base solid $border-color__base;
    margin-bottom: $indent__xl;
    padding: $indent__s;

    .order-title {
        border-bottom: $border-width__base solid $border-color__base;
        padding: 0 0 $indent__s;

        > strong {
            @include lib-font-size(24);
            font-weight: $font-weight__regular;
        }

        .action {
            display: inline-block;
        }
    }

    .items-qty {
        @extend .abs-reset-list;

        .item {
            white-space: nowrap;
        }

        .title {
            &::after {
                content: ': ';
            }
        }
    }

    .table-wrapper {
        margin: 0;

        + .actions-toolbar {
            display: none;
        }

        &:not(:last-child) {
            margin-bottom: $indent__l;
        }
    }

    .table-order-items {
        .product-item-name {
            margin-top: 0;
        }

        tbody + tbody {
            border-top: $border-width__base solid $border-color__base;
        }
    }

    .item-options {
        dt {
            margin: 0;
        }

        dd {
            margin: 0 0 15px;
        }

        &.links {
            dt {
                display: inline-block;

                &::after {
                    content: ': ';
                }
            }

            dd {
                margin: 0;
            }
        }
    }
}

.order-pager-wrapper {
    .toolbar-amount {
        padding-top: 3px;
    }

    .pages-items {
        .item {
            &:first-child,
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.order-actions-toolbar {
    margin-bottom: 25px;

    .action {
        margin-right: 30px;

        &.print {
            margin: 0;
        }
    }
}

.order-status {
    @extend .abs-status;
}

.page-title-wrapper {
    .order-date {
        margin: 0 0 $indent__base;
    }
}

.account,
[class^='sales-guest-'],
.sales-guest-view {
    .page-title-wrapper {
        .page-title {
            margin-right: $indent__m;
        }
    }
}

.form-orders-search {
    .field {
        &.email,
        &.zip {
            margin-bottom: 0;
        }
    }
}

.block-reorder {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item {
        .field.item {
            display: inline-block;
        }
    }

    .product-item-name {
        display: inline-block;
    }

    .choice {
        margin-top: $indent__s;
    }

    .actions-toolbar {
        margin: 17px 0;
        max-width: 178px;
    }

    .sidebar-main & .subtitle,
    .sidebar-additional & .subtitle {
        @extend .abs-no-display;
    }
}

.block-order-details {
    &-comments {
        margin: 0 0 40px;

        .comment-date {
            font-weight: $font-weight__semibold;
        }

        .comment-content {
            line-height: 1.6;
            margin: 0 0 20px;
        }
    }

    &-view {
        .box-content {
            .payment-method {
                .title {
                    font-weight: $font-weight__regular;
                }

                .content {
                    margin: 0;

                    > strong {
                        font-weight: $font-weight__regular;

                        &::after {
                            content: ': ';
                        }
                    }
                }
            }
        }
    }
}

.order-tracking {
    border-bottom: $border-width__base solid $border-color__base;
    margin: 0;
    padding: 20px 0;

    .tracking-title {
        display: inline-block;
    }

    .tracking-content {
        display: inline-block;
        margin: 0 0 0 5px;
    }
}

//
//  Guest order view page
//  ---------------------------------------------

[class^='sales-guest-'],
.sales-guest-view {
    .column.main {
        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .order-details-items {
            .table-order-items {
                .product-item-name,
                .price-including-tax,
                .price-excluding-tax,
                .items-qty {
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }

        .toolbar {
            @extend .abs-add-clearfix-mobile;

            .pages {
                width: 100%;
            }

            .limiter {
                clear: both;
            }
        }
    }

    .order-details-items {
        thead {
            display: block;

            tr,
            td {
                display: block;
            }
        }
    }

    .table-wrapper {
        .table.table-order-items {
            tfoot {
                td.order-pager-wrapper {
                    width: 100%;
                }
            }
        }
    }

    .account,
    [class^='sales-guest-'],
    .sales-guest-view {
        @extend .abs-title-orders-mobile;
    }
}

@include max-screen($screen__m) {
    .order-pager-wrapper {
        .toolbar-amount {
            left: inherit;
            position: relative;
            text-align: center;
            top: inherit;
        }

        .pages {
            text-align: center;
        }

        .action.previous,
        .action.next {
            margin: 0;
        }
    }

    .block-reorder {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .order-details-items {
        .order-title {
            .action {
                margin: 12px 0 0 30px;

                &.track {
                    float: right;
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .order-status {
        @extend .abs-status-desktop;
    }

    .order-links {
        @extend .abs-add-clearfix-desktop;

        .item {
            float: left;
            margin: 0 $tab-control__margin-right 0 0;

            a {
                padding: $tab-control__padding-top $tab-control__padding-right;
            }

            strong {
                border-bottom: 0;
                margin-bottom: -1px;
                padding: $tab-control__padding-top $tab-control__padding-right $tab-control__padding-bottom + 1 $tab-control__padding-left;
            }
        }
    }

    .table-order-items {
        .subtotal,
        .amount {
            text-align: right;
        }

        &.creditmemo {
            .col {
                &.qty,
                &.discount,
                &.subtotal {
                    text-align: center;
                }

                &.total {
                    text-align: right;
                }
            }
        }
    }

    .order-pager-wrapper {
        .order-pager-wrapper-top {
            padding-left: 0;
            padding-right: 0;
        }

        .pages {
            float: right;
        }
    }

    .order-actions-toolbar {
        .action.print {
            display: block;
            float: right;
        }
    }

    .page-title-wrapper {
        .order-date {
            $order-status-indent: ceil($h1__margin-bottom__desktop/2);
            @include lib-css(margin-top, -$order-status-indent);
        }
    }

    .order-details-items {
        margin-top: -1px;
        padding: 25px;

        .col {
            &.name {
                padding-left: 0;
            }

            &.price {
                text-align: center;
            }

            &.subtotal {
                text-align: right;
            }
        }

        tbody td {
            padding-bottom: 20px;
            padding-top: 20px;
        }

        tfoot {
            .amount,
            .mark {
                text-align: right;
            }
        }

        &.ordered {
            .order-title {
                display: none;
            }
        }
    }

    //
    //  Guest order view page
    //  ---------------------------------------------

    [class^='sales-guest-'],
    .sales-guest-view {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                        margin-bottom: $indent__base;
                    }
                }
            }
        }
    }

    .account,
    [class^='sales-guest-'],
    .sales-guest-view {
        @extend .abs-title-orders-desktop;

        .column.main .block.block-order-details-view {
            @extend .abs-add-clearfix-desktop;

            .block-content:not(.widget) .box {
                @extend .abs-add-box-sizing-desktop;
                clear: none;
                float: left;
                width: (100% / 4);
            }
        }
    }
}
