@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Open-sans/regular/OpenSans-Regular',
    $font-weight: 400,
    $font-style: $font-weight__regular
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Open-sans/semi-bold/OpenSans-SemiBold',
    $font-weight: $font-weight__semibold,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Open-sans/bold/OpenSans-Bold',
    $font-weight: $font-weight__bold,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__alternative,
    $font-path: '../fonts/Rift/demi/Rift-Demi',
    $font-weight: regular,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__alternative,
    $font-path: '../fonts/Rift/bold/rift-bold',
    $font-weight: 500,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}
