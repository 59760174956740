.faq-list {
    @extend .abs-margin-for-blocks-and-widgets;

    &__item {
        position: relative;
        border: 1px solid $color-pink-light1;
        transition: 200ms;

        &:not(:last-child) {
            margin-bottom: $indent__s;
        }

        &:hover {
            border-color: $color-pink-light4;
        }
    }

    &__trigger {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-color: $brand__primary__color
        );
        display: block;
        cursor: pointer;
        padding: 15px 30px 15px 15px;
        user-select: none;

        &::before {
            position: absolute;
            right: 15px;
            transition: 200ms;
        }

        &[aria-expanded=true] {
            font-weight: $font-weight__bold;

            &:before {
                transform: rotate(180deg);
            }
        }
    }

    &__description {
        display: none;
        padding: 0 15px 5px;
    }

    &__inner {
        .std {
            margin-bottom: 20px;
        }
    }

    & &__feedback {
        border: none;
        font-size: $font-size__xs;
        display: flex;
        align-items: center;
        padding: 0;
    }

    &__feedback-text {
        margin-right: 15px;
    }

    &__btn {
        color: $brand__primary__color;
        background-color: $theme-color-light-gray5;
        padding: 5px 8px;
        border: 1px solid $theme-color-light-gray5;
        border-radius: 5px;
        transition: 200ms;

        &:active,
        &:focus {
            border-color: $theme-color-light-gray5;
            color: $brand__primary__color;
            background-color: $theme-color-light-gray5;
        }

        &:hover {
            border-color: $brand__primary__color;
            color: $brand__primary__color;
            background-color: $theme-color-light-gray5;
        }

        &--dislike {
            @include lib-icon-font(
                $_icon-font-content: $icon-thumbs-down,
                $_icon-font-size: 16px,
                $_icon-font-margin: 0 4px 0 0,
                $_icon-font-color : $brand__primary__color
            );
        }

        &--like {
            @include lib-icon-font(
                $_icon-font-content: $icon-thumbs-up,
                $_icon-font-size: 18px,
                $_icon-font-margin: -3px 4px 0 0,
                $_icon-font-color : $brand__primary__color
            );
            margin-right: 10px;
        }

        > span {
            font-size: $font-size__xs;
            text-transform: uppercase;
        }
    }
}

.faqs-list {
    a {
        @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-color: $brand__primary__color
        );
        display: block;
        cursor: pointer;
        padding: 15px 30px 15px 15px;
        user-select: none;
        border: 1px solid $color-pink-light1;
        transition: 200ms;
        color: $primary__color;

        &:active,
        &:visited,
        &:focus,
        &:hover {
            color: $primary__color;
        }

        &:hover {
            border-color: $color-pink-light4;
        }

        &::before {
            position: absolute;
            right: 15px;
            transition: 200ms;
        }
    }
}

#faq-search-form {
    display: flex;
    width: 100%;

    .field {
        flex: 1;
        display: flex;
        max-width: 450px;
    }

    .control {
        @include lib-form-input-text-xl();
        display: flex;
        width: 100%;
    }

    #text-search {
        height: auto;
    }

    .action.primary {
        @extend .btn--lg;
    }
}

.faq-search-form {
    .column.main > & {
        margin-bottom: 60px;
    }
}

.faq-content {
    .faqcat-title {
        margin-bottom: $indent__base;
    }
}
