.login-wrapper {
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showlogin',
        $_options-selector                    : '.block-minilogin',
        $_dropdown-toggle-icon-content        : $icon-chevron-down,
        $_dropdown-toggle-active-icon-content : $icon-chevron-up,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_icon-font-position                  : after,
        $_icon-font-size                      : 11px,
        $_icon-font-line-height               : 11px,
        $_icon-font-margin                    : 0 0 0 5px,
        $_icon-font-color                     : $minicart-icons-color,
        $_icon-font-color-hover               : $minicart-icons-color-hover,
        $_icon-font-color-active              : $minicart-icons-color
    );
    display: block;

    .block-minilogin {
        right: 0;
        width: 300px;
        z-index: $z-index__dropdown-menu;
        overflow: hidden;
        border-radius: $dropdown-list-pointer__border-radius;
    }

    .form-login {
        padding: 15px;
    }

    .field {
        margin-bottom: 10px;
    }

    .dropdown-footer {
        padding: 15px;
        background-color: $panel__background-color;
        line-height: 1.3;
        font-size: 14px;

        > span {
            display: block;
            margin-bottom: $indent__s;
        }

        .btn {
            display: block;
            text-align: center;
            background-color: $primary__color__darker;
            border-color: $primary__color__darker;
            padding: 10px 22px;

            &:hover {
                background-color: $primary__color__dark;
                border-color: $primary__color__dark;
            }
        }
    }
}

.page-header {
    .customer-welcome {
        list-style-type: none;
        position: relative;
        margin-bottom: 0;
        padding: 0 8px;
        height: 45px;
        display: flex;
        align-items: center;

        &.active {
            .customer-menu {
                display: block;
            }
        }

        .customer-menu {
            display: none;
            position: absolute;
            background-color: $color-white;
            box-shadow: $dropdown-list__shadow;
            border-radius: $dropdown-list-pointer__border-radius;
            right: 0;
            z-index: 10;
            min-width: 20rem;
            top: 100%;

            .header.links {
                list-style-type: none;

                li {
                    margin-bottom: 0;
                }

                a {
                    display: block;
                    padding: 12px 16px;
                    color: $color-gray20;
                    line-height: 1.4;
                }
            }
        }
    }

    .customer-name {
        height: 45px;
        display: flex;
        align-items: center;

        &.active {
            > svg {
                fill: $brand__primary__color;
            }
        }
    }
}

.login-wrapper.logged-in {
    display: none;
}

.customer-welcome .login-wrapper {
    display: block;
}

.customer-menu {
    .header.links {
        display: flex;
        flex-direction: column;

        .login-wrapper {
            &.logged-in {
                order: 4;
            }
        }
    }
}
