.category-grid-widget {
    &__anchor {
        @include lib-link(
            $_link-color                   : #111111,
            $_link-color-visited           : #111111,
            $_link-color-hover             : #111111,
            $_link-color-active            : #111111
        );
        font-weight: $font-weight__semibold;
        font-size: 14px;
    }
}
