//
//    Common
//--------------------------------------
$spinner-color-from: #d8d8d8;
$spinner-color-to: #d8d8d8;

$hr-color: #F6F6F6;
$hover-bg: #f8f8f8;
$mute-color: #999;
$text-color: #333;

.mst-searchautocomplete__autocomplete {
    left: 50% !important; // sass-lint:disable-line no-important
    transform: translateX(-50%);

    .mst-searchautocomplete {
        &__index {
            padding: $indent__s;
        }

        &__index-title {
            text-align: left;
            font-family: $font-family-name__alternative;
            font-weight: $font-weight__medium;
            font-size: $font-size__l;
            color: $primary__color__darker;

            > span:last-child {
                color: $mute-color;
                font-weight: $font-weight__regular;
                margin-left: 10px;
                font-size: 1.4rem;
            }
        }

        &__item {
            position: relative;

            &.magento_catalog_category,
            &.magento_cms_page {
                font-size: 1.4rem;

                a {
                    color: $brand__primary__color;
                    text-decoration: none;
                }
            }

            &.magento_catalog_product {
                padding: 1.5rem;
                gap: 0.5rem;

                .title {
                    display: flex;
                    flex-direction: column;
                }

                .sku {
                    font-size: 1.4rem;
                }

                .price {
                    margin-left: 0;
                }

                .to-cart {
                    float: none;
                    margin: 0;
                }
            }

            .to_cart_message {
                position: absolute;
                font-size: $font-size__xs;
                bottom: 0;
                right: 1.5rem;

                &.success {
                    color: $accept__color;
                }
            }
        }

        &__highlight {
            font-weight: $font-weight__bold;
        }

        &__spinner {
            position: absolute;
            overflow: hidden;
            top: 0;
            height: 0.2rem;
            width: 100%;
        }

        &__progress {
            background-color: $brand__primary__color;
            position: absolute;
            bottom: 0;
            top: 0;
            width: 25%;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-name: indeterminate-progress-bar;
        }
    }

    .mst {
        &__add_to_cart {
            padding: 0;

            &__icon {
                @include lib-icon-font(
                    $_icon-font-content: $icon-cart
                );

                &:before {
                    padding: 1rem 1.5rem;
                }
            }

            &__text {
                @include lib-visually-hidden();
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .mst-searchautocomplete__autocomplete {
        max-height: calc(100vh - 240px);
        overflow-y: auto;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .mst-searchautocomplete__autocomplete {
        width: 80vw !important; // sass-lint:disable-line no-important

        .mst-searchautocomplete {
            &__results {
                > div:not(.mst-searchautocomplete__show-all) {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
            }

            &__index {
                padding: $indent__base;

                &.magento_catalog_product {
                    grid-column: 2 / span 2;
                    grid-row: 1 / span 2;
                    border-left: 1px solid $hr-color;
                }

                &.magento_catalog_category {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                }

                &.magento_cms_page {
                    grid-column: 1 / span 1;
                    grid-row: 2 / span 1;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .mst-searchautocomplete__autocomplete {
        width: 860px !important; // sass-lint:disable-line no-important
    }
}

@keyframes indeterminate-progress-bar {
    from {
        left: -50%;
    }
    to {
        left: 100%;
    }
}
