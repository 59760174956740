.product-saleable-status .message {
    border: 1px solid $brand__primary__color;
    background-color: $color-pink-light5;
    font-size: $font-size__s;
    padding: 15px;

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: $brand__primary__color;
        text-decoration: underline;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-saleable-status .message {
        margin-bottom: $indent__m;
    }
}
