.ub-mega-menu {
    margin-bottom: 0;

    &,
    * {
        box-sizing: border-box;
    }

    &.level0 {
        list-style-type: none;
        padding-left: 0;
    }

    .level1 {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    .level2 {
        list-style-type: none;
        padding-left: 0;
    }

    .level3 {
        list-style-type: none;
        padding-left: 0;
    }

    .level4 {
        list-style-type: none;
        padding-left: 0;
    }

    .level5 {
        list-style-type: none;
        padding-left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .nav-sections {
        .ub-mega-menu {
            &.level0 {
                display: flex;
                flex-direction: column;

                > .mega {
                    margin-bottom: 0;

                    > .mega {
                        display: block;
                        padding: 15px;
                        font-family: $font-family-name__alternative;
                        font-weight: $font-weight__medium;
                        font-size: $font-size__l;
                        color: $primary__color__darker;

                        &:hover {
                            text-decoration: none;
                        }

                        &.has-child {
                            position: relative;
                            padding-right: 30px;

                            @include lib-icon-font(
                                $_icon-font-content: $icon-down,
                                $_icon-font-size: $font-size__s,
                                $_icon-font-position: after,
                                $_icon-font-display: block,
                                $_icon-font-color: $brand__primary__color
                            );

                            &:after {
                                position: absolute;
                                right: 15px;
                                top: 50%;
                                transform: translateY(-50%);
                                transition: transform 200ms;
                            }

                            &.active {
                                &:after {
                                    transform: translateY(-50%) rotate(180deg);
                                }
                            }
                        }
                    }

                    > .child-content {
                        background-color: $theme-color-light-gray5;
                    }

                    &.has-child {
                        > .child-content {
                            display: none;

                            &.active {
                                display: block;
                            }
                        }
                    }

                    > .menu-group-link {
                        display: none;
                        background-color: $theme-color-light-gray5;
                        padding: 15px 15px 15px 30px;
                        font-family: $font-family-name__alternative;
                        color: $text__color;
                        font-size: 16px;

                        &.active {
                            display: block;
                        }
                    }

                    &.featured {
                        order: 5;

                        > .mega {
                            color: $brand__primary__color;
                        }
                    }
                }
            }

            .level2 {
                > .mega {
                    margin-bottom: 0;

                    > .mega {
                        display: block;
                        padding: 15px 15px 15px 30px;
                        font-family: $font-family-name__alternative;
                        font-size: 16px;
                        color: $text__color;

                        &:active,
                        &:hover {
                            text-decoration: none;
                        }

                        &.active {
                            color: $primary__color__darker;
                        }

                        &.has-child {
                            position: relative;
                            padding-right: 30px;

                            @include lib-icon-font(
                                $_icon-font-content: $icon-down,
                                $_icon-font-size: $font-size__s,
                                $_icon-font-position: after,
                                $_icon-font-display: block,
                                $_icon-font-color: $brand__primary__color
                            );

                            &:after {
                                position: absolute;
                                right: 15px;
                                top: 50%;
                                transform: translateY(-50%);
                                transition: transform 200ms;
                            }

                            &.active {
                                &:after {
                                    transform: translateY(-50%) rotate(180deg);
                                }
                            }
                        }
                    }

                    > .menu-group-link {
                        display: none;
                        background-color: $theme-color-light-gray4;
                        padding: 15px 15px 15px 45px;
                        font-family: $font-family-name__alternative;
                        color: $text__color;

                        &.active {
                            display: block;
                        }
                    }

                    > .child-content {
                        background-color: $theme-color-light-gray4;

                        > .child-content-inner {
                            > .mega-col {
                                width: 100% !important; // sass-lint:disable-line no-important -- overriding inline element styling
                            }
                        }
                    }
                }

                > .mega {
                    &.has-child {
                        > .child-content {
                            &:not(.active) {
                                display: none;
                            }
                        }
                    }
                }
            }

            .level3 {
                > .mega {
                    margin-bottom: 0;

                    > .mega {
                        display: block;
                        padding: 15px 15px 15px 45px;
                        font-family: $font-family-name__alternative;
                        font-size: 16px;
                        color: $text__color;

                        &:active,
                        &:hover {
                            text-decoration: none;
                        }

                        &.active {
                            color: $primary__color__darker;
                        }

                        &.has-child {
                            position: relative;
                            padding-right: 30px;

                            @include lib-icon-font(
                                $_icon-font-content: $icon-down,
                                $_icon-font-size: $font-size__s,
                                $_icon-font-position: after,
                                $_icon-font-display: block,
                                $_icon-font-color: $brand__primary__color
                            );

                            &:after {
                                position: absolute;
                                right: 15px;
                                top: 50%;
                                transform: translateY(-50%);
                                transition: transform 200ms;
                            }

                            &.active {
                                &:after {
                                    transform: translateY(-50%) rotate(180deg);
                                }
                            }
                        }
                    }

                    > .menu-group-link {
                        display: none;
                        background-color: $theme-color-light-gray4;
                        padding: 5px 15px 5px 60px;
                        font-size: $small-font-size;
                        color: $text__color;

                        &.active {
                            display: block;
                        }
                    }

                    &.group {
                        > .level4 {
                            display: none;

                            &.active {
                                display: block;
                            }
                        }
                    }
                }
            }

            .level4 {
                > .mega {
                    margin-bottom: 0;

                    > .mega {
                        display: block;
                        padding: 5px 15px 5px 60px;
                        font-size: $small-font-size;
                        color: $text__color;
                    }
                }
            }

            .level5 {
                > .mega {
                    margin-bottom: 0;

                    > .mega {
                        display: block;
                        padding: 5px 15px 5px 75px;
                        font-size: $font-size__s;
                        color: $text__color;
                    }
                }
            }

            > .mega:not(.style-tabs) {
                &.has-child {
                    > .child-content {
                        > .child-content-inner {
                            width: 100% !important; // sass-lint:disable-line no-important -- overriding inline element styling

                            > .mega-col {
                                width: 100% !important; // sass-lint:disable-line no-important -- overriding inline element styling
                            }
                        }
                    }
                }

                .level1 {
                    > .mega {
                        margin-bottom: 0;

                        > .mega {
                            display: block;
                            padding: 15px 15px 15px 30px;
                            font-family: $font-family-name__alternative;
                            font-size: 16px;
                            color: $text__color;

                            &:active,
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }

        .nav-sections-item-content.nav-sections-item-content {
            & > ul.header.links {
                .customer-menu > ul.header.links li,
                .login-wrapper {
                    a {
                        padding: 15px !important;
                        font-family: $font-family-name__alternative;
                        font-size: 16px;
                        color: $primary__color__darker;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    $submenu-width: 250px;

    .nav-sections {
        .ub-mega-menu {
            &.level0 {
                display: flex;
                position: relative;

                > .mega {
                    margin-bottom: 0;

                    &:not(:first-child) {
                        margin-left: 40px;
                    }

                    > .mega {
                        display: block;
                        position: relative;
                        padding: 15px 0;
                        color: $color-white;
                        font-family: $font-family-name__alternative;
                        font-size: $menu-font-size;

                        &:after {
                            content: '';
                            position: absolute;
                            display: block;
                            background-color: $color-white;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            transform: translateY(100%);
                            opacity: 0;
                            visibility: hidden;
                            transition: 200ms;
                        }
                    }

                    > a.mega {
                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &.has-child {
                        > .child-content {
                            position: absolute;
                            z-index: $z-index-1;
                            background-color: $color-white;
                            opacity: 0;
                            visibility: hidden;
                            transition: 200ms;
                        }

                        &:hover,
                        &.mega-hover {
                            > .child-content {
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        &.style-tabs {
                            > .child-content {
                                width: 100%;
                                left: 0;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    background-color: $theme-color-light-gray5;
                                    width: $submenu-width;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    z-index: -1;
                                }
                            }
                        }
                    }

                    &:hover,
                    &.mega-hover {
                        > .mega {
                            &:after {
                                transform: translateY(0);
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    &.featured {
                        order: 5;
                        margin-left: auto;

                        > .mega {
                            background-color: $color-white;
                            padding: $indent__xs $indent__base;
                            color: $brand__primary__color;
                            margin-block: 7px;
                            font-weight: $font-weight__bold;
                            font-size: 18px;
                            transition: 100ms;
                            border: 2px solid $brand__primary__color;

                            &:hover {
                                border-color: $color-black;
                                color: $color-black;
                            }
                        }
                    }
                }
            }

            .level1 {
                > .mega {
                    &.group {
                        position: relative;
                    }
                }
            }

            .level2 {
                > .mega {
                    &.has-child {
                        > .child-content {
                            display: none;
                        }
                    }
                }
            }

            .style-tabs {
                .level2 {
                    > .mega {
                        margin-bottom: 0;

                        > .mega {
                            &.tab-head {
                                display: block;
                                max-width: $submenu-width;
                                padding: 10px 15px;
                                background-color: $theme-color-light-gray5;
                                color: $primary__color__darker;
                                font-family: $font-family-name__alternative;
                                font-size: $menu-font-size;
                                transition: 200ms;
                            }
                        }

                        > a.mega {
                            &.tab-head {
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }

                        &.has-child {
                            > .mega {
                                &.tab-head {
                                    position: relative;
                                    padding-right: 30px;

                                    @include lib-icon-font(
                                        $_icon-font-content: $icon-next,
                                        $_icon-font-size: $font-size__xs,
                                        $_icon-font-position: after,
                                        $_icon-font-display: block,
                                        $_icon-font-color: $brand__primary__color
                                    );

                                    &:after {
                                        position: absolute;
                                        right: 15px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        transition: transform 200ms;
                                    }
                                }
                            }

                            > .child-content {
                                position: absolute;
                                left: $submenu-width;
                                max-width: calc(100% - #{$submenu-width});
                                width: 100%;
                                top: 0;

                                > .child-content-inner {
                                    display: flex;

                                    > .mega-col {
                                        padding: 15px;
                                    }
                                }
                            }

                            &.active {
                                > .child-content {
                                    display: block;
                                }
                            }
                        }

                        &.active {
                            > .mega {
                                &.tab-head {
                                    background-color: $color-white;
                                }
                            }
                        }
                    }
                }

                .level3 {
                    > .mega {
                        margin-bottom: $indent__base;

                        > .mega {
                            display: block;
                            font-family: $font-family-name__alternative;
                            font-size: $menu-font-size;
                            color: $brand__primary__color;
                            line-height: $line-height__l;
                        }

                        > a.mega {
                            transition: color 200ms;

                            &:hover {
                                text-decoration: none;
                                color: $brand__primary__color__dark;
                            }
                        }
                    }
                }

                .level4 {
                    > .mega {
                        margin-bottom: 0;

                        > .mega {
                            display: inline-block;
                            color: $text__color;
                            font-size: $small-font-size;
                            line-height: $line-height__l;
                        }
                    }
                }

                .level5 {
                    > .mega {
                        margin-bottom: 0;

                        > .mega {
                            display: inline-block;
                            color: $text__color;
                            font-size: $font-size__s;
                            margin-left: $indent__s;
                        }
                    }
                }

                .level4,
                .level5 {
                    > .mega {
                        > a.mega {
                            @extend .link-hover;
                            transition: color 200ms;
                        }
                    }
                }
            }

            > .mega:not(.style-tabs) {
                .level1 {
                    > .mega {
                        margin-bottom: 0;

                        > .mega {
                            display: block;
                            padding: 10px 15px;
                            color: $primary__color__darker;
                            font-family: $font-family-name__alternative;
                            font-size: $menu-font-size;
                        }

                        > a.mega {
                            transition: 200ms;

                            &:hover {
                                text-decoration: none;
                                background-color: $theme-color-light-gray5;
                            }
                        }
                    }
                }
            }
        }

        .menu-group-link {
            display: none;
        }
    }

    .ub-sub-menu-opened {
        .page-wrapper {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
