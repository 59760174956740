.amcheckout-block {
    padding: $indent__base;
    background-color: $theme-color-light-gray5;

    > li {
        list-style-type: none;
    }

    .amcheckout-title,
    .step-title {
        font-family: $amcheckout-step-title__font-family;
        font-size: $amcheckout-step-title__font-size;
        color: $amcheckout-step-title__color;
        margin-bottom: $indent__base;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .amcheckout-block {
        margin-bottom: $indent__l;
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .amcheckout-block {
        &:not(:last-child) {
            margin-bottom: $indent__l;
        }
    }
}
