#credit-form {
    margin-bottom: 10px;

    h2 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    #credit-amount,
    p {
        margin-bottom: 10px;
    }

    button {
        &:hover,
        &:focus,
        &:visited {
            color: $theme-pink;
            background-color: #fff;
            border: 1px solid #c8cedc;
        }
    }
}
