.checkout-payment-methods {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        list-style-type: none;
        gap: $indent__base; // sass-lint:disable-line no-misspelled-properties

        img {
            height: 30px !important; // sass-lint:disable-line no-important
            width: auto !important; // sass-lint:disable-line no-important
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-methods {
        margin-left: auto;
        flex-basis: calc((100% - 60px) / 3);
    }
}
