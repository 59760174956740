.checkout-header {
    margin-bottom: $indent__base;
    order: -1;

    .title {
        margin-bottom: 0;
    }

    .description {
        font-size: $font-size__s;
    }
}

//
//  Mobile
//  _____________________________________________
//@include max-screen($screen__m) {
//
//}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .amcheckout-main-container {
        &.-layout-3columns {
            display: flex;

            .amcheckout-column {
                flex-basis: 33%;

                &:not(:last-child) {
                    margin-right: $indent__l;
                }
            }
        }
    }
}
