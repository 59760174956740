//
//    Common
//--------------------------------------
$spinner-color-from: #d8d8d8;
$spinner-color-to: #d8d8d8;

$hr-color: #F6F6F6;
$hover-bg: #f8f8f8;
$mute-color: #999;
$text-color: #333;

.mst-searchautocomplete__autocomplete {
    @extend .abs-search-styles;
    display: none !important;
    position: absolute;
    top: 3.1rem;
    left: 0;
    margin-top: 10px;
    box-sizing: border-box;
    min-height: 3.5rem;
    z-index: 1000;
    text-align: left;

    &._active {
        display: block !important;
    }

    .mst-searchautocomplete__spinner {
        display: none;

        img {
            width: 25px;
        }
    }

    &._loading {
        .mst-searchautocomplete__spinner {
            display: block;
        }
    }

    .mst-searchautocomplete__empty-result {
        text-align: center;
        padding: 8px 10px;
        color: $mute-color;
        font-size: 1.2rem;
        display: block;
    }

    .mst-searchautocomplete__show-all {
        text-align: center;
        background-color: $hr-color;

        a {
            padding: 8px 10px;
            color: $mute-color;
            font-size: 1.2rem;
            display: block;
        }
    }

    .mst-searchautocomplete__index-title {
        padding: 8px 10px;
        text-align: right;
        font-size: 13px;
        line-height: 13px;
        color: $mute-color;
    }

    .mst-searchautocomplete__close {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            @include lib-clearfix();
            margin: 0;
            padding: 5px 1rem;
            cursor: pointer;

            a.title {
                margin-bottom: 0;
                text-decoration: none;
            }

            &:hover {
                background-color: $menu__item__hover;
            }
        }
    }
}

.mst-searchautocomplete__index {
    &:not(:last-of-type) {
        //border-bottom: 1px solid $border-color__base;
    }

    &.popular {
        .index-title {
            background: none;
            text-align: left;
            color: #999;
        }

        ul {
            li {
                float: left;
                padding: .7rem 0 .7rem 1rem;
                margin: 0;

                a {
                    font-size: 1.3rem;
                    color: #575757;
                }

                &:hover {
                    background: none;
                    cursor: pointer;

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.mst-searchautocomplete__item {
    &.magento_catalog_product {
        display: flex;
        align-items: flex-start;

        img {
            width: 40px;
            margin-right: 1rem;
        }

        .title {
            display: block;
            margin-bottom: 0.5rem;

            a {
                color: $text-color;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            .sku {
                color: $mute-color;
            }
        }

        .meta {
            flex-grow: 1;
            overflow: hidden;
        }

        .description {
            font-size: 1.2rem;
            color: $mute-color;
            height: 3rem;
            line-height: 1.5rem;
            overflow: hidden;
            margin-bottom: 0.5rem;
        }

        .price {
            display: block;
            font-weight: 600;
            margin-left: 1rem;
            white-space: nowrap;
            font-size: 1.3rem;

            .price-label {
                margin-right: 1rem;
            }
        }

        .product-reviews-summary {
            margin: 0;
            .reviews-actions {
                display: none;
            }
        }

        .to-cart {
            float: right;;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
    }

    &.magento_catalog_category {
        a {
            i {
                color: $mute-color;
                display: inline-block;
                margin: 0 .5rem;
                font-style: normal;
            }
        }
    }

    &.magento_search_query {
        a {
            float: left;

            &.title {
                color: $text__color;
                font-weight: $font-weight__medium;
            }
        }

        .num_results {
            float: right;
            color: $mute-color;
        }
    }

    &.magento_catalog_categoryproduct {
        a {
            &.title {
                font-size: 13px;
            }
        }

        .mst-searchautocomplete__highlight {
            color: $text__color;
            font-size: $font-size__base;
        }
    }
}

.mst-searchautocomplete__highlight {
    font-weight: $font-weight__regular;
}

.mst-searchautocomplete__spinner {
    position: relative;
    margin: auto;

    .spinner-item {
        position: absolute;
        top: 0;
        background-color: $spinner-color-from;
        width: 20px;
        height: 20px;
        animation-name: bounce_fountainG;
        animation-duration: 0.645s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        transform: scale(.3);
        border-radius: 15px;

        &.spinner-item-1 {
            left: 0;
            animation-delay: 0.256s;
        }

        &.spinner-item-2 {
            left: 20px;
            animation-delay: 0.3225s;
        }

        &.spinner-item-3 {
            left: 40px;
            animation-delay: 0.389s;
        }

        &.spinner-item-4 {
            left: 60px;
            animation-delay: 0.4555s;
        }

        &.spinner-item-5 {
            left: 80px;
            animation-delay: 0.522s;
        }

        &.spinner-item-6 {
            left: 100px;
            animation-delay: 0.5885s;
        }

        &.spinner-item-7 {
            left: 120px;
            animation-delay: 0.645s;
        }

        &.spinner-item-8 {
            left: 140px;
            animation-delay: 0.7115s;
        }
    }
}

@keyframes bounce_fountainG {
    0% {
        transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-o-keyframes bounce_fountainG {
    0% {
        -o-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -o-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-ms-keyframes bounce_fountainG {
    0% {
        -ms-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -ms-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-webkit-keyframes bounce_fountainG {
    0% {
        -webkit-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -webkit-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-moz-keyframes bounce_fountainG {
    0% {
        -moz-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -moz-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    .mst-searchautocomplete__autocomplete {
        width: 100%;
    }
}

//
//    Mobile
//--------------------------------------

@include max-screen($screen__m) {
    .mst-searchautocomplete__autocomplete {
        .mst-searchautocomplete__spinner {
            top: -38px;
        }
    }
}

@include max-screen($screen__s) {
    .mst-searchautocomplete__autocomplete {
        .mst-searchautocomplete__close {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            font-weight: bold;
            color: #999;
            font-size: 1.6rem;
            padding: 1rem;
            line-height: 1rem;
            cursor: pointer;
            display: none;
        }
    }
}
