.product-labels {
    position: absolute;
    top: 20px;
    width: 100%;
    z-index: $z-index__product-labels;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;

    .product-overview.products-list & {
        right: 0;
    }

    .product-overview.products-grid & {
        right: 8px;
    }

    .swiper-container & {
        right: 0;
    }

    .product.media & {
        right: 0;
    }
}

.product-label {
    box-sizing: border-box;
    max-width: 50%;
    margin-bottom: 20px;
    line-height: 1;

    .product.media & {
        max-width: 30%;
    }

    &--image {
        img {
            display: block;

            &[src$=".svg"] {
                width: 100%;
            }
        }
    }

    &--text {
        position: relative;
        max-width: 45%;
    }

    &__triangle {
        position: absolute;
        right: 99%;
        height: 100%;

        .ie11 &,
        .ie10 & {
            right: 96%;
            width: 18px;
        }

        .ie11 .product-labels--small &,
        .ie10 .product-labels--small & {
            right: 93%;
        }
    }

    &__inner {
        padding: 10px 15px 10px 10px;
        background-color: $theme-color-yellow2;
        color: $color-white;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.04em;
        font-weight: $font-weight__semibold;

        .product-labels--small & {
            padding: 7px 12px 7px 7px;
            font-size: 11px;
        }

        .product.media & {
            font-size: 15px;
        }
    }
}

@include min-screen($screen__m) {
    .product-labels {
        .product.media & {
            right: 15px;
        }
    }
}

@include min-screen($screen__l) {
    .product-labels {
        .product.media & {
            right: 44px;
        }
    }
}
