.std {
    @extend .abs-margin-for-blocks-and-widgets;

    table {
        margin-bottom: $indent__m;

        tr {
            border-bottom: 1px solid $border-color__base;

            > td,
            > th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.sidebar {
    .footer-menu__top-link {
        @include lib-heading(h3);
        display: inline-block;
    }

    .footer-menu__sub-menu-topall {
        display: none;
    }

    .footer-menu__sub-menu-list {
        @include abs-sidebar-list();
        margin-bottom: 30px;
    }
}

.cms-contact {
    .std {
        a {
            @include lib-link(
                $_link-color: $brand__primary__color,
                $_link-color-visited: $brand__primary__color,
                $_link-color-hover: $brand__primary__color,
                $_link-color-active: $brand__primary__color
            );
        }

        ul,
        li {
            @include lib-list-reset-styles();

            &::before {
                display: none;
            }
        }
    }
}
