.breadcrumbs {
    @include lib-breadcrumbs();
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .breadcrumbs {
        margin-bottom: 15px;
        
        .items {
            font-size: 1.1rem;
        }
    }
}
