.show-more {
    margin-bottom: $indent__s;

    &__container {
        &--initialized {
            max-height: 105px;
            overflow: hidden;
            transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
        }

        &--open {
            max-height: 100rem;
            transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
        }
    }
    &__text {
        &--initialized {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: $indent__xs;
            animation: close 0.1s linear 0.1s forwards;
        }

        &--open {
            animation: open 0.1s linear 0s forwards;
        }
    }

    &__button {
        &.btn--as-link {
            color: $brand__primary__color;
            text-decoration: underline;
        }
    }
}

@keyframes open {
    from {
        line-clamp: 3;
        -webkit-line-clamp: 3;
    }
    to {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }
}

@keyframes close {
    from {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }
    to {
        line-clamp: 3;
        -webkit-line-clamp: 3;
    }
}
