.footer-menu {
    > .mega {
        > .child-content {
            > .child-content-inner {
                display: flex;
            }
        }
    }

    .level1 {
        > .mega {
            > .mega {
                @include lib-heading(h4);
                @include lib-link(
                    $_link-color: #111111,
                    $_link-color-visited: #111111,
                    $_link-color-hover: #111111,
                    $_link-color-active: #111111
                );
                font-size: 17px;
                font-weight: $font-weight__bold;
                display: inline-block;
                line-height: 1.7;
            }

            &.group {
                > .menu-group-link {
                    display: none;
                }
            }
        }
    }

    .level2 {
        > .mega {
            margin-bottom: 2px;

            > .mega {
                @include lib-link(
                    $_link-color: $text__color,
                    $_link-color-visited: $text__color,
                    $_link-color-hover: $text__color,
                    $_link-color-active: $text__color
                );
                display: inline-block;
                line-height: 1.7;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .footer-menu {
        padding: $indent__l 0 $indent__s;

        > .mega {
            > .child-content {
                > .child-content-inner {
                    flex-direction: column;

                    .mega-col {
                        &:not(.last) {
                            margin-bottom: $indent__base;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .footer-menu {
        > .mega {
            > .child-content {
                > .child-content-inner {
                    > .mega-col {
                        flex: 1;
                    }
                }
            }
        }

        .level1 {
            > .mega {
                > a.mega {
                    @extend .link-hover;
                }
            }
        }

        .level2 {
            > .mega {
                > a.mega {
                    @extend .link-hover;
                }
            }
        }
    }
}
