/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.ui-datepicker {
    display: none;
    z-index: 999999 !important;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-next-hover span, .ui-datepicker .ui-datepicker-prev-hover span {
    border-color: transparent #ff8400 transparent transparent;
}
.ui-datepicker .ui-datepicker-prev {
    left: 2px;

    &.ui-state-disabled {
        opacity: .5;
    }
}
.ui-datepicker .ui-datepicker-next {
    right: 2px;
    text-align: center;

    &.ui-state-disabled {
        opacity: .5;
    }
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: none;
}
.ui-datepicker .ui-datepicker-next span {
    display: none;
}
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
}
.ui-datepicker td span, .ui-datepicker td a {
    display: block;
    padding: 4px;
    text-align: right;
    text-decoration: none;
    color: #000000;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}
.ui-datepicker-rtl {
    direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dt {
    height: 25px;
    margin-bottom: -22px;
}
.ui-timepicker-div dl .ui_tpicker_time_label {
    margin-bottom: -25px;
}
.ui-timepicker-div dl dd {
    margin: 0 10px 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}
.ui-datepicker {
    padding: 15px;
    border: #ffffff 4px solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #efefef;
}
.ui-datepicker .ui-datepicker-header {
    background: transparent;
    border: none;
    padding-bottom: 10px;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    width: 47%;
    margin-right: 6%;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
    width: 47%;
}
.ui-datepicker .ui-datepicker-calendar td {
    border: #cfcfcf 1px solid;
}
.ui-datepicker .ui-datepicker-calendar td a {
    padding: 4px;
    display: block;
}
.ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
    background: #f9eae7;
}
.ui-datepicker .ui-datepicker-calendar {
    background: #ffffff;
    border: #cfcfcf 1px solid;
    border-collapse: collapse;
}
.ui-datepicker .ui-datepicker-calendar th {
    background: #efefef;
    border: #cfcfcf 1px solid;
    padding: 4px;
    text-transform: uppercase;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
    text-align: center;
    border: #cfcfcf 1px solid;
}
.ui-datepicker {
    width: auto;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
    background: #f3d7d2;
}
.ui-datepicker .ui-datepicker-calendar .ui-state-active {
    background: #ffdf66;
}
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dd {
    margin: 0 0 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}
.ui-slider {
    position: relative;
    text-align: left;
}
.ui-slider-horizontal .ui-slider-handle {
    margin-left: -5px;
}
.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    cursor: default;
}
.ui-slider-horizontal {
    height: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    background: #dadada;
}
.ui-slider-handle {
    height: 10px;
    width: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #f98b25;
    display: block;
    position: absolute;
}
.ui-timepicker-div {
    padding: 10px 0 5px 0;
}
.ui-datepicker .ui-datepicker-buttonpane {
    overflow: hidden;
    padding-top: 10px;
    border-top: #cfcfcf 1px solid;
}
.ui-datepicker .ui-datepicker-calendar .ui-state-disabled {
    background: #f9f9f9;
}
.ui-datepicker .ui-datepicker-calendar .ui-state-disabled span {
    color: #959595;
}
/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    position: absolute; /* must have */
    z-index: -1; /* must have */
    filter: mask(); /* must have */
    top: -4px; /* must have */
    left: -4px; /* must have */
    width: 200px; /* must have */
    height: 200px; /* must have */
}

._has-datepicker.admin__control-text {
    padding-right: 3.5rem;
    width: 15rem;
}
._has-datepicker ~ .ui-datepicker-trigger {
    position: absolute;
    top: 1px;
    right: 7px;
}
._has-datepicker + .ui-datepicker-trigger {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    text-decoration: none;
    display: inline-block;
    height: 3.2rem;
    margin-left: -4.2rem;
    overflow: hidden;
    position: absolute;
    vertical-align: top;
    z-index: 1;
    right: 7px;
    top: 0;
}
._has-datepicker + .ui-datepicker-trigger:focus,
._has-datepicker + .ui-datepicker-trigger:active {
    background: none;
    border: none;
}
._has-datepicker + .ui-datepicker-trigger:hover {
    background: none;
    border: none;
}
._has-datepicker + .ui-datepicker-trigger.disabled,
._has-datepicker + .ui-datepicker-trigger[disabled],
fieldset[disabled] ._has-datepicker + .ui-datepicker-trigger {
    pointer-events: none;
    opacity: 0.5;
}
._has-datepicker + .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
._has-datepicker + .ui-datepicker-trigger:active {
    transform: scale(0.975);
}
._has-datepicker + .ui-datepicker-trigger + .admin__control-support-text,
._has-datepicker + .ui-datepicker-trigger + .admin__control-label {
    margin-left: 3.7rem;
}
._has-datepicker + .ui-datepicker-trigger img {
    display: none;
}
.ui-datepicker {
    box-sizing: border-box;
    display: none;
    opacity: 0.98;
    padding: 23px 20px;
    width: auto;
    z-index: 999999 !important;
}
.ui-datepicker:before {
    background: #ffffff;
    border: 1px solid #007bdb;
    bottom: 3px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 3px;
    z-index: 0;
}
.ui-datepicker-header {
    padding: 0 0 10px;
    position: relative;
    z-index: 1;
}
.ui-datepicker-prev,
.ui-datepicker-next {
    cursor: pointer;
    line-height: 3rem;
    position: absolute;
    top: 0;
}
.ui-datepicker-prev:before,
.ui-datepicker-next:before {
    color: #514943;
    display: inline-block;
    font-size: 34px;
}
.ui-datepicker-prev {
    left: 0;
}
.ui-datepicker-prev:before {
    content: '\2039';
}
.ui-datepicker-next {
    right: 0;
}
.ui-datepicker-next:before {
    content: '\203A';
}
.ui-datepicker .ui-datepicker-title {
    line-height: 1.8em;
    margin: 0 2.3em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
    min-width: 0;
}
.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}
.ui-datepicker table {
    width: 100%;
}
.ui-datepicker table.ui-datepicker-calendar {
    background: #ffffff;
    border: 0;
    border-collapse: collapse;
    position: relative;
    z-index: 1;
}
.ui-datepicker table.ui-datepicker-calendar thead {
    background: transparent;
}
.ui-datepicker table.ui-datepicker-calendar tr {
    background: transparent;
}
.ui-datepicker table.ui-datepicker-calendar tr th {
    background: transparent;
    border: 0;
    padding: 0;
}
.ui-datepicker table.ui-datepicker-calendar tr th span {
    font-size: 12px;
    font-weight: 700;
    line-height: 28px;
}
.ui-datepicker table.ui-datepicker-calendar tr td {
    background: transparent;
    border: 1px solid #adadad;
    padding: 0;
}
.ui-datepicker table.ui-datepicker-calendar span,
.ui-datepicker table.ui-datepicker-calendar a {
    box-sizing: border-box;
    color: #514943;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    width: 38px;
}
.ui-datepicker table.ui-datepicker-calendar .ui-state-disabled span {
    background: #f5f5f5;
    color: #999999;
}
.ui-datepicker table.ui-datepicker-calendar .ui-state-active {
    background: #514943;
    color: #ffffff;
}
.ui-datepicker table.ui-datepicker-calendar .ui-datepicker-today a {
    border: 3px solid #adadad;
    line-height: 32px;
}
.ui-datepicker .ui-datepicker-buttonpane {
    overflow: hidden;
    padding-top: 15px;
    position: relative;
    white-space: nowrap;
    z-index: 1;
}
.ui-datepicker .ui-datepicker-buttonpane button,
.ui-datepicker .ui-datepicker-buttonpane .block-footer .action-add {
    background: #ffffff;
    border: 1px solid #adadad;
    border-radius: 1px;
    box-sizing: border-box;
    color: #008bdb;
    float: left;
    font-size: 14px;
    line-height: 38px;
    padding: 0;
    text-align: center;
    width: 49%;
}
.ui-datepicker .ui-datepicker-buttonpane .ui-datepicker-close {
    float: right;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    margin-right: 6%;
    width: 47%;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
    width: 47%;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
    border: 1px solid #cecece;
    text-align: center;
}
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dd {
    margin: 0 0 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}
.ui-slider {
    position: relative;
    text-align: left;
}
.ui-slider-horizontal .ui-slider-handle {
    margin-left: -5px;
}
.ui-slider .ui-slider-handle {
    cursor: default;
    position: absolute;
    z-index: 2;
}
.ui-slider-horizontal {
    background: #adadad;
    border: none;
    border-radius: 10px;
    height: 10px;
}
.ui-slider-handle {
    background: #514943;
    border-radius: 10px;
    display: block;
    height: 10px;
    position: absolute;
    width: 10px;
}
.ui-timepicker-div {
    padding: 10px 0 5px 0;
    position: relative;
}
.ui-datepicker-rtl {
    direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
    left: auto;
    right: 2px;
}
.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
    left: auto;
    right: 1px;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button,
.ui-datepicker-rtl .ui-datepicker-buttonpane .block-footer .action-add {
    float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-buttonpane .block-footer .action-add.ui-datepicker-current {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dt {
    height: 25px;
    margin-bottom: -22px;
}
.ui-timepicker-div dl .ui_tpicker_time_label {
    margin-bottom: -25px;
}
.ui-timepicker-div dl dd {
    margin: 0 10px 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}
.field.field-dob input {
    width: 100%;
}
