.checkout-shipping-address {
    .amcheckout-button {
        &.-new-address {
            display: block;
            border: none;
            background: transparent;
            font-family: $font-family-name__alternative;
            margin: 0 0 0 auto;
            padding-right: 0;
            padding-left: 0;
            font-weight: $font-weight__bold;
            font-size: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $primary__color__darker;

            &:before {
                content: '';
                width: 12px;
                height: 12px;
                background-image: url('../images/icons/icon-plus--pink.svg');
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                margin-right: 5px;
                transition: 200ms;
            }

            &:hover {
                &:before {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.form-shipping-address {
    .field {
        &[name^="shippingAddress.street."] {
            > .label {
                display: none;
            }
        }
    }
}

.shipping-address-item {
    position: relative;
    border: 1px solid $theme-color-light-gray2;
    background: $color-white;
    transition: 200ms;
    padding: $indent__base $indent__base $indent__base $indent__xl;
    margin-bottom: $indent__base;
    font-size: 14px;

    &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        background-color: $theme-color-light-gray3;
        background-image: url('../images/icons/icon-check.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;
        border-radius: 50%;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: 200ms;
    }

    &.selected-item {
        border-color: $brand__primary__color;
        background-color: $color-pink-light5;

        &:before {
            opacity: 1;
            visibility: visible;
            background-color: $brand__primary__color;
        }
    }

    &:not(.selected-item) {
        cursor: pointer;

        &:hover {
            border-color: $theme-color-light-gray3;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .select-address {
        display: block;
        color: $brand__primary__color;
        font-family: $font-family-name__alternative;
        font-weight: $font-weight__bold;
        font-size: 13px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-top: $indent__s;
    }
}
