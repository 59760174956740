.faq-categories {
    display: flex;
    flex-wrap: wrap;

    &,
    * {
        box-sizing: border-box;
    }

    @include max-screen(420px) {
        @include grid-alt(1);
    }

    @include screen(420px, $screen__s) {
        @include grid-alt(2);
    }

    @include screen($screen__s, $screen__l) {
        @include grid-alt(3);
    }

    @include min-screen($screen__l) {
        @include grid-alt(5);
    }

    &__anchor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: $indent__base;
        border: 4px solid $color-pink-light1;
        height: 100%;
        color: $primary__color__darker;
        transition: color 200ms;

        > img {
            max-width: 30%;
        }

        &:active,
        &:visited,
        &:focus {
            color: $primary__color__darker;
        }

        &:hover {
            text-decoration: none;
            color: $brand__primary__color;
        }
    }

    &__title {
        font-family: $font-family-name__alternative;
        font-weight: $font-weight__bold;
        text-align: center;
        margin-top: $indent__base;
        font-size: $faq-categorie-font-size;
    }
}
