// Have to overwrite the styling form the script itself which uses the element ID, that's the reason for all the !importants
.footer-newsletter {
    ._form {
        width: 100% !important; // sass-lint:disable-line no-important
        padding: 0 !important; // sass-lint:disable-line no-important
        margin-top: 0 !important; // sass-lint:disable-line no-important
    }

    ._form-title {
        font-family: $font-family-name__alternative !important; // sass-lint:disable-line no-important
    }

    ._submit {
        height: 44px !important; // sass-lint:disable-line no-important
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}
