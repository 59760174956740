.checkout-link-home {
    order: 3;
    transition: color 200ms;
    font-family: $font-family-name__alternative;
    font-weight: $font-weight__bold;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;

    &,
    &:visited,
    &:focus {
        color: $text__color;
    }

    &:hover,
    &:active {
        color: $color-black;
    }

    @media (max-width: 340px) {
        font-size: 12px;
    }
}
