.amcheckout-shipping-methods {
    .amcheckout-method {
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid $theme-color-light-gray2;
        background: $color-white;
        transition: 200ms;
        padding: $indent__base $indent__base $indent__base $indent__xl;

        &:not(:last-child) {
            margin-bottom: $indent__base;
        }

        &:before {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            background-color: $theme-color-light-gray3;
            background-image: url('../images/icons/icon-check.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px;
            border-radius: 50%;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            transition: 200ms;
        }

        &.-selected {
            border-color: $brand__primary__color;
            background-color: $color-pink-light5;

            &:before {
                opacity: 1;
                visibility: visible;
                background-color: $brand__primary__color;
            }
        }

        &:not(.-selected) {
            cursor: pointer;

            &:hover {
                border-color: $theme-color-light-gray3;

                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .col {
            border: none;
            padding: 0;
        }

        .col-method {
            &--input {
                display: none;
            }
        }

        .col-price {
            vertical-align: middle;
            font-size: 14px;
            margin-left: auto;
            color: $theme-text-color-darker;
        }

        .col-info {
            .col-method {
                font-weight: $font-weight__bold;
                color: $theme-text-color-darker;
            }

            .col-carrier {
                font-size: 12px;
            }
        }
    }
}
