.checkout-payment-method {
    .form.payments {
        > .fieldset {
            > .legend,
            > br {
                display: none;
            }
        }
    }

    .payment-method {
        align-items: center;
        position: relative;
        border: 1px solid $theme-color-light-gray2;
        background: $color-white;
        transition: 200ms;
        padding: $indent__base $indent__base $indent__base $indent__xl;

        &:not(:last-child) {
            margin-bottom: $indent__base;
        }

        &:before {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            background-color: $theme-color-light-gray3;
            background-image: url('../images/icons/icon-check.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px;
            border-radius: 50%;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            transition: 200ms;
        }

        &._active {
            border-color: $brand__primary__color;
            background-color: $color-pink-light5;

            &:before {
                opacity: 1;
                visibility: visible;
                background-color: $brand__primary__color;
            }
        }

        &:not(._active) {
            cursor: pointer;

            &:hover {
                border-color: $theme-color-light-gray3;

                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .payment-method-title {
                .label {
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .payment-method-title {
        .radio {
            display: none;
        }

        .label {
            display: flex;
            align-items: center;
            font-weight: $font-weight__bold;
            color: $theme-text-color-darker;

            img {
                width: 28px;
                height: auto !important; // sass-lint:disable-line no-important
                margin-right: 8px;
            }
        }
    }

    .payment-method-content {
        > p {
            margin-bottom: 0;
        }

        .fieldset.payment {
            > .field {
                margin-bottom: 0;
            }

            .label {
                font-size: 12px;
            }
        }

        .actions-toolbar {
            display: none;
        }
    }
}

.payment-option {
    .action-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.credit {
        .payment-option-inner {
            font-size: 14px;

            strong {
                font-weight: $font-weight__bold;
            }
        }

        .actions-toolbar {
            .action {
                font-family: $font-family-name__alternative;
                padding-right: 0;
                padding-left: 0;
                font-weight: $font-weight__bold;
                font-size: 16px;
                letter-spacing: 1px;
                text-transform: uppercase;
                background-color: transparent;
                border: none;
                color: $primary__color__darker;
            }
        }
    }
}
