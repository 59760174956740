.share-product {
    margin-left: auto;
    position: relative;

    &__button {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: $primary__color__dark;
        border: none;
        gap: 0.5rem; // sass-lint:disable-line no-misspelled-properties
        font-weight: $font-weight__bold;
        transition: color 100ms;

        &:focus {
            background-color: transparent;
            border: none;
            color: $primary__color__dark;
        }

        &:hover,
        &:active {
            background-color: transparent;
            border: none;
            color: $brand__primary__color;

            .share-product {
                &__icon {
                    transform: scale(1.1) rotate(15deg);
                }
            }
        }
    }

    &__icon {
        fill: $brand__primary__color;
        transition: transform 100ms;
    }

    &__tooltip {
        position: absolute;
        white-space: nowrap;
        font-size: $font-size__s;
        background-color: $color-pink-light5;
        box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25);
        top: 0;
        left: 0;
        transform: translateX(-100%);
        padding: 4px 8px;
        border: 1px solid $brand__primary__color;
        color: $brand__primary__color;
    }
}
