//
//  Checkout Estimated Total
//  ---------------------------------------------

.opc-estimated-wrapper {
    @extend .abs-add-clearfix;
    @extend .abs-no-display-desktop;
    @include lib-css(border-bottom, $border-width__base solid $color-gray80);
    margin: 0 0 15px;
    padding-bottom: 15px;

    .minicart-wrapper {
        .action {
            &.showcart {
                &::before {
                    @include lib-css(color, $primary__color);
                }
            }
        }
    }

    .minicart-wrapper {
        @include lib-dropdown(
            $_toggle-selector                     : '.action.showcart',
            $_options-selector                    : '.block-minicart',
            $_dropdown-toggle-icon-content        : $icon-cart,
            $_dropdown-toggle-active-icon-content : $icon-cart,
            $_dropdown-list-item-padding          : false,
            $_dropdown-list-item-hover            : false,
            $_icon-font-position                  : before,
            $_icon-font-size                      : 23px,
            $_icon-font-line-height               : 23px,
            $_icon-font-color                     : $text__color,
            $_icon-font-color-hover               : $text__color,
            $_icon-font-color-active              : $text__color
        );
        float: right;

        .action {
            &.showcart {
                @include lib-icon-font(
                    $_icon-font-content: $icon-chevron-down,
                    $_icon-font-position: after,
                    $_icon-font-margin: 0 0 0 7px,
                    $_icon-font-size: 11px,
                    $_icon-font-color: $text__color
                );
                padding: 7px 10px;
                position: relative;
                white-space: nowrap;

                &.active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content  : $icon-chevron-up,
                        $_icon-font-position : after
                    );
                }

                .text {
                    @extend .abs-visually-hidden;
                }

                .counter.qty {
                    @extend .abs-number-badge;
                    left: 5px;
                    top: -2px;
                }

                .counter-label {
                    @extend .abs-visually-hidden;
                }

                &::after {
                    margin-left: 15px;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-estimated-wrapper {
        display: none;
    }
}
