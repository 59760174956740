.breadcrumbs {
    li {
        @include lib-list-reset-styles();

        strong {
            font-weight: 600;
            color: $brand__primary__color;
        }
    }
}

.subcategories {
    @include lib-list-reset-styles();

    &__items {
        &--level2 {
            @include lib-list-reset-styles();
            padding-left: $indent__s;
            border-left: 1px solid $border-color__base;
            font-size: .8em;
        }
    }

    &__current-cat {
        font-weight: $font-weight__bold;
    }
}

//
//  Responsive
//  _____________________________________________

@include max-screen($screen__m) {
    .subcategories {
        display: none;
    }
}
