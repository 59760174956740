.combideals-wrapper {
    margin-bottom: 40px;
}

.combideal {
    margin-bottom: 20px;
    border: 1px solid $theme-border-color;
    padding: 25px 15px 15px;
    
    &__images {
        flex: 1;
    }

    &__titles {
        @include lib-list-reset-styles();
        flex-wrap: wrap;
        margin: 0 0 25px;
        padding: 0 0 20px;
        border-bottom: 1px solid $theme-border-color;
        font-weight: 600;
    
    }

    &__title {
        font-family: $font-family-name__base;
        display: inline;
        margin: 0 0 5px;
        font-weight: 600;
        
        a {
            color: #111111;
        }
        
        &:not(:last-of-type) {
            &::after {
                content: '+';
                margin: 0 7px 0 10px;
                color: $theme-icon-color-light;
                font-size: 19px;
                line-height: 1;
            }
        }
    }

    &__details {
        > .price-final_price,
        .special-price,
        .normal-price,
        .minimal-price {
            .price {
                @extend .abs-price-lg;
                font-size: 31px;
            }
        }
    }

    &__discount {
        @extend .abs-accept-text;
        margin-bottom: 10px;
    }

    &__images {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-left: -15px;
    }

    &__image-wrapper {
        position: relative;
        padding-left: 15px;
        text-align: center;
        width: (100% / 3);
        box-sizing: border-box;

        &:not(:first-child) {
            @include lib-icon-font(
                $_icon-font-content: $icon-expand,
                $_icon-font-color: $theme-icon-color-light
            );
            font-size: 11px;

            &::before {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }

    &__button {
        @include lib-icon-font(
            $_icon-font-content: $icon-cart,
            $_icon-font-margin: 0 10px 0 -3px,
            $_icon-font-size: 18px,
            $_icon-font-color: $brand__primary__color
        );
        
        color: $brand__primary__color;
        background: #ffe9f5;
        border: #ffe9f5;
        
        &:hover,
        &:visited,
        &:focus,
        &:active {
            background: darken(#ffe9f5, 2%);
            color: $brand__primary__color;
            border: #ffe9f5;
        }
    }
}

@include min-screen($screen__l) {
    .combideal {
        &__content {
            display: flex;
            flex-wrap: wrap;
        }

        &__images {
            padding-right: 15px;
        }
    }
}

@include min-screen($screen__m) {
    .combideals-wrapper {
        margin-bottom: 60px;
    }
}

@include max-screen($screen__l) {
    .combideal {
        width: 45%;
    }
}

@include max-screen($screen__m) {
    .combideal {
        width: 82%;
    }
}
