.webforms {
    .form {
        .fieldset {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            padding-right: 0;

            > .field,
            > .fields > .field {
                margin-bottom: $indent__base;

                .choice {
                    padding-left: 0;
                    padding-right: 0;

                    input {
                        vertical-align: middle;
                    }

                    label {
                        margin-left: $indent__xs;
                    }
                }

                &.required {
                    > .label {
                        &:after {
                            margin-left: 0;
                        }
                    }
                }
            }

            > .legend {
                padding-left: 1rem;
                padding-right: 1rem;
                margin-bottom: $indent__s;
            }
        }

        .actions-toolbar {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .webforms-fields-comment {
        position: absolute;
        font-size: $font-size__xs;
        margin-bottom: 0;
        margin-top: 0;
    }

    .webforms-datepicker {
        margin-bottom: 0;
    }

    .customer-dob {
        label {
            position: absolute;
            font-size: $font-size__xs;
        }
    }
}

@media screen and (min-width: 20em) {
    .webforms {
        .wf-sm-1,
        .wf-sm-1-1,
        .wf-sm-12-12 {
            &.fieldset {
                // Using these negative margins to align the content of the form with the content of the rest of the page
                width: calc(100% + 2rem);
                margin-left: -1rem;
                margin-right: -1rem;
            }
        }
    }
}

@media screen and (min-width: 48em) {
    .webforms {
        .wf-md-1,
        .wf-md-1-1,
        .wf-md-12-12 {
            &.fieldset {
                // Using these negative margins to align the content of the form with the content of the rest of the page
                width: calc(100% + 2rem);
                margin-left: -1rem;
                margin-right: -1rem;
            }
        }
    }
}

@media screen and (min-width: 64em) {
    .webforms {
        .wf-lg-1,
        .wf-lg-1-1,
        .wf-lg-12-12 {
            &.fieldset {
                // Using these negative margins to align the content of the form with the content of the rest of the page
                width: calc(100% + 2rem);
                margin-left: -1rem;
                margin-right: -1rem;
            }
        }
    }
}
