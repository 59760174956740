//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-white !default;
$checkout-summary__padding          : 20px !default;

$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    @extend .abs-add-box-sizing;
    display: flex;
    flex-direction: column;

    > .title {
        @extend .abs-checkout-title;
        order: 1;
        display: block;
        margin-bottom: 20px;
    }

    .items-in-cart {
        order: 2;
    }

    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
        order: 3;
        margin-top: 20px;
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(15);
                padding-top: 0;
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        margin: 0;

        > .title > strong {
            @include lib-typography(
                $_font-size: 12px
            );
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(margin, 0 -($checkout-summary-items__padding) 0 0);
        border: 0;

        &.overflowed {
            max-height: 300px;
            overflow-y: auto;
        }
    }

    ul.minicart-items {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding-left: 20px;
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 5px;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: $indent__xs;
            text-align: left;
        }

        .product-item-name {
            display: inline;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            @include lib-font-size(15);
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    @include lib-font-size(10);
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
