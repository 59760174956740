//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l !default;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height !default;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color !default;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-edit !default;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 18px !default;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 20px !default;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px !default;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color !default;

//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    .shipping-information-title {
        @extend .abs-checkout-title;
        @include lib-css(border-bottom, $checkout-step-title__border);
        margin: 0 0 $indent__base;
        position: relative;
        
        .action-edit {
            @extend .abs-action-button-as-link;
            @include lib-css(top, $checkout-sidebar-shipping-information-edit-icon__top);
            @include lib-icon-font(
                    $checkout-sidebar-shipping-information-edit-icon__content,
                $_icon-font-size         : $checkout-sidebar-shipping-information-edit-icon__font-size,
                $_icon-font-line-height  : $checkout-sidebar-shipping-information-edit-icon__line-height,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $checkout-sidebar-shipping-information-edit-icon__color,
                $_icon-font-color-hover  : $checkout-sidebar-shipping-information-edit-icon__hover__color,
                $_icon-font-color-active : $checkout-sidebar-shipping-information-edit-icon__color
            );
            margin: 0;
            position: absolute;
            right: 0;
        }
    }
    
    .shipping-information-content {
        @include lib-css(line-height, $checkout-sidebar-shipping-information__line-height);
        .actions-toolbar {
            margin-left: 0;
        }
        
        a {
            @include lib-link(
                $_link-color                   : $theme-pink,
                $_link-color-visited           : $theme-pink,
                $_link-color-hover             : $theme-pink,
                $_link-color-active            : $theme-pink
            );
        }
    }
    
    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }
}

@include max-screen($screen__m) {
    .opc-block-shipping-information {
        @include lib-css(padding, 0 $checkout-sidebar-shipping-information__padding);
    }
}
