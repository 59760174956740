$footer__background-color: false !default;

.page-footer {
    background-color: $theme-color-light-gray5;
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

//.footer-menu {
//    &,
//    &__sub-menu-list {
//        @include lib-list-reset-styles();
//    }
//}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 40px;
    text-align: center;
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    font-size: 12px;
}

.footer-payment-providers {
    margin-bottom: 10px;
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;
    }

    h4 {
        @include lib-heading(h4);
        @include lib-link(
            $_link-color: #111111,
            $_link-color-visited: #111111,
            $_link-color-hover: #111111,
            $_link-color-active: #111111
        );
        font-size: 17px;
        font-weight: $font-weight__bold;
        display: inline-block;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }
}

.bottom-footer-wrapper {
    padding: 30px 0;
    background-color: $theme-color-light-gray5;
    border-top: 1px solid #d9d9d9;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        img {
            float: right;
        }

        .copyright {
            float: left;
        }
    }
}

.top-footer {
    margin-bottom: 60px;
    padding: 25px 0;
    background-color: white;
    color: #111111;
    border-top: 1px solid #e5d0c5;

    &__left {
        margin: 5px 15px 5px 0;
        font-size: 22px;
    }

    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
        }

        li {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-size: 22px
            );
            margin: 0;
            font-weight: $font-weight__semibold;
            padding-right: 30px;

            &:before {
                content: '';
                background-image: url('../images/usp.png');
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
            }

            &:not(:first-child) {
                margin-left: 55px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        @include make-row();
        margin-bottom: 60px;

        //.footer-menu {
        //    display: flex;
        //
        //    &__item {
        //        flex: 1;
        //    }
        //
        //    &__top-link,
        //    &__sub-menu-link {
        //        @include lib-link(
        //            $_link-color: $text__color,
        //            $_link-color-visited: $text__color,
        //            $_link-color-hover: $text__color,
        //            $_link-color-active: $text__color
        //        );
        //    }
        //
        //    &__sub-menu-topall {
        //        display: none;
        //    }
        //
        //    &__top-link {
        //        @include lib-heading(h4);
        //        @include lib-link(
        //            $_link-color: #111111,
        //            $_link-color-visited: #111111,
        //            $_link-color-hover: #111111,
        //            $_link-color-active: #111111
        //        );
        //        font-size: 17px;
        //        font-weight: $font-weight__bold;
        //        display: inline-block;
        //        margin-bottom: 20px;
        //    }
        //
        //    &__sub-menu-item {
        //        margin-bottom: 8px;
        //        line-height: 1.2;
        //    }
        //}

        .after-footer-columns,
        .footer-menu {
            @include make-col-ready();
        }

        .after-footer-columns {
            @include make-col(12);
        }

        .footer-menu {
            @include make-col(12);
        }
    }

    .footer-bottom {
        margin-bottom: 60px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        margin-bottom: 60px;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen__m) {
    //.footer.content .footer-menu {
    //    margin-right: -$layout-indent__width;
    //    margin-left: -$layout-indent__width;
    //
    //    &__item {
    //        @include lib-icon-font(
    //            $_icon-font-content: $icon-next,
    //            $_icon-font-display: block
    //        );
    //        position: relative;
    //        margin: 0;
    //        padding: 15px 35px 15px $layout-indent__width;
    //        border-bottom: 1px solid $border-color__base;
    //
    //        &:first-child {
    //            border-top: 1px solid $border-color__base;
    //        }
    //
    //        &::before {
    //            position: absolute;
    //            top: 50%;
    //            right: $layout-indent__width;
    //            transform: translateY(-50%);
    //        }
    //    }
    //
    //    &__top-link {
    //        @include lib-link(
    //            $_link-color: $text__color,
    //            $_link-color-visited: $text__color,
    //            $_link-color-hover: $text__color,
    //            $_link-color-active: $text__color
    //        );
    //    }
    //
    //    &__sub-menu-list {
    //        display: none;
    //    }
    //}

    .footer-columns {
        .block.newsletter {
            display: none;
        }

        .social-media {
            margin-top: 20px;
        }
    }


    .top-footer {
        display: none;
    }
}
